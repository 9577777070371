.submit-btn-highlight {
    background-color: #6E48AA;
    border-color: white;
    border-width: 3px;
    border-style: dotted;
    margin: 2px;
}

.submit-btn-highlight:hover {
    background-color: #410985;
}

.submit-btn-normal:hover {
    background-color: darkblue;
}

.button-fill-theme {
    background-color: #6E48AA;
    border-color: white;
    color: white;
    /* border-width: 3px; */
}

.button-fill-theme:hover {
    background-color: #410985;
    border-color: white;
    color: white;

}

.button-fill-theme:active {
    background-color: #410985;
    border-color: white;
    color: white;
    box-shadow: 0 0 0 0.25rem rgba(100, 80, 253, 0.5) !important
}

.btn-fill-theme {
    background-color: #6E48AA;
    border-color: white;
    color: white;
    /* border-width: 3px; */
}

.btn-fill-theme:hover {
    background-color: #410985;
    border-color: white;
    color: white;

}

.btn-fill-theme:active {
    background-color: #410985;
    border-color: white;
    color: white;
    box-shadow: 0 0 0 0.25rem rgba(100, 80, 253, 0.5) !important
}

.button-outline-theme {
    background-color: white !important;
    border-color: #6E48AA !important;
    color: #6E48AA !important;
    /* border-width: 3px; */
}

.button-outline-theme:hover {
    background-color: #6E48AA !important;
    border-color: white !important;
    color: white !important;

}

.button-outline-theme:focus {
    background-color: #6E48AA !important;
    border-color: white !important;
    color: white !important;
    box-shadow: 0 0 0 0.25rem rgba(100, 80, 253, 0.5) !important
}

.btn-outline-theme {
    background-color: white !important;
    border-color: #6E48AA !important;
    color: #6E48AA !important;
    /* border-width: 3px; */
}

.btn-outline-theme:hover {
    background-color: #6E48AA !important;
    border-color: white !important;
    color: white !important;

}

.btn-outline-theme:focus {
    background-color: #6E48AA !important;
    border-color: white !important;
    color: white !important;
    box-shadow: 0 0 0 0.25rem rgba(100, 80, 253, 0.5) !important
}



#container {
    width: 80%;
    /* Can be in percentage also. */
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
}

.successful-modal {
    width: 60vm;
    max-width: 60vm
}

.matrix {
    border-collapse: collapse;
    width: 100%;
}

.matrix th,
.matrix td {
    padding: 5px 1px 5px 1px;
    text-align: center;
    /* border-bottom: 1px solid #ddd; */
}

.matrix th:first-child,
.matrix td:first-child {
    border-right: 2px solid #ddd;
    min-width: 20em;
    max-width: 40em;
    min-height: 4em;
    max-height: 7em;

    /* min-width: 8em;
    max-width: 12em; */
    word-break: normal
}

.matrix thead tr:last-child th {
    border-bottom: 2px solid #ddd;
}

.matrix-header {
    border-bottom: 2px solid #ddd;
    font-weight: bold;
}

.error-message {
    color: red;
    padding: 10px;
    margin: 10px 0;
    font-weight: bold;
}

#example-collapse-text {
    border: 2px solid #ddd;
}

.preview-question {
    padding: 10px 20px 10px 20px;
    margin-top: 10px;
    border: 3px dotted rgba(110, 72, 170, 0.406);
    border-radius: 10px;
}

.preview-question-2 {
    padding: 10px;
    margin-top: 10px;
    border: 2px dashed #6E48AA;
    border-radius: 10px;
}

.conditional-container {
    padding: 10px;
    margin: 5px 10px 10px 15px;
    /*top, right, bottom, left*/
    /* margin-left:12px; */
    border: 2px dashed #6E48AA;
    border-radius: 10px;
}

.conditional-container-2 {
    padding: 10px;
    margin: 5px 10px 10px 15px;
    /*top, right, bottom, left*/
    /* margin-left:12px; */
    border: 2px groove gray;
    border-radius: 10px;
}

.square-family {
    width: 19px;
    height: 19px;
    /* margin-top: 2px; */
    padding: 0;
    background-color: rgba(225, 199, 199, 0.892);
    border: 2px outset white;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    /* add relative positioning */
    top: 2px;
    /* add a top offset of 3 pixels */
    /* adds rounded corners */
}