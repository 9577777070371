.blinking {
    animation: blinkingText 0.8s;
}

@keyframes blinkingText {
    0% {
        color: #000;
    }

    49% {
        color: transparent;
    }

    50% {
        color: transparent;
    }

    99% {
        color: transparent;
    }

    100% {
        color: #000;
    }
}